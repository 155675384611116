import { render, staticRenderFns } from "./UidMobile.vue?vue&type=template&id=847639d2&scoped=true&"
import script from "./UidMobile.vue?vue&type=script&lang=js&"
export * from "./UidMobile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "847639d2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDialog,VDivider,VList,VListItem,VListItemContent,VListItemSubtitle,VTextField})
